import { Badge } from '@codecademy/gamut';
import { theme } from '@codecademy/gamut-styles';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { forwardRef } from 'react';

import { ChatIcon } from './ChatIcon';
import { ChatToolTip } from './ChatToolTip';

const StyledChatBtn = styled.button(
  css({
    border: 'none',
    padding: 0,
    background: 'none',
    cursor: 'pointer',
    position: 'relative', // Ensure positioning does not affect tooltip
    '&:focus-visible': {
      outline: `0.3rem auto ${theme.colors.primary}`,
    },
    display: 'flex',
    '&:hover': {
      svg: {
        '.icon-fill': {
          fill: theme.colors['navy-200'],
        },
      },
    },
  })
);

const Wrapper = styled.div(
  css({
    position: 'relative',
    display: 'flex',
  })
);

const NewBadge = styled(Badge)(
  css({
    zIndex: 1,
    position: 'absolute',
    transform: 'translate(0px, 4px)',
  })
);

export const ChatBtn = forwardRef<
  HTMLButtonElement,
  { onClick: () => void; showToolTip: boolean }
>(({ onClick, showToolTip }, ref) => (
  <Wrapper>
    <ChatToolTip showToolTip={showToolTip}>
      <StyledChatBtn ref={ref} type="button" onClick={onClick} tabIndex={0}>
        <NewBadge size="sm" variant="accent">
          New
        </NewBadge>
        <ChatIcon />
      </StyledChatBtn>
    </ChatToolTip>
  </Wrapper>
));
