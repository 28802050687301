import {
  Alert,
  Anchor,
  Box,
  Dialog,
  FlexBox,
  IconButton,
  InfoTip,
  Text,
} from '@codecademy/gamut';
import {
  ArrowChevronDownIcon,
  SparkleIcon,
  TrashIcon,
} from '@codecademy/gamut-icons';
import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { sendCaptchaGuardedRequest } from '@mono/ui/captcha';
import { NextLink } from '@mono/ui/components';
import { useEffect, useMemo, useRef, useState } from 'react';

import { trackUserClick } from '~/libs/tracking';

import { AnimatedLoadingDots } from '../../../InterviewSimulator/components/AnimatedLoadingDots';
import { ChatInput } from './ChatInput';
import { Greeting } from './Greeting';
import { anonChat } from './helpers/api';
import { Message, MessageBubble } from './MessageBubble';

const b36uuid = () => {
  const hexUuid = crypto.randomUUID().replace(/-/g, '');
  return BigInt(`0x${hexUuid}`).toString(36); // 0x to parse as hex, base36 to be compact
};

const isoTimestamp = () => new Date().toISOString();

const ScrollBox = styled(FlexBox)`
  overscroll-behavior: contain;
`;

const StyledText = styled(Text)`
  color: rgba(16, 22, 47, 0.75);
`;

type ChatBoxProps = {
  onClose: () => void;
  pageName: string;
};

const getChatToken = async (recaptchaToken: string, version: number) => {
  try {
    const f = await fetch('/api/portal/anon-chat-token', {
      method: 'POST',
      body: JSON.stringify({ recaptchaToken, version }),
    });
    const chatToken = await f.json();
    return { allowed: true, result: chatToken };
  } catch {
    return { allowed: false, result: 'Error while verifying recaptcha token' };
  }
};

type ChatSessionState = {
  messages: Message[];
  conversationId: string;
  chatToken: string;
  expId: string;
};
const CHAT_SESSION_STATE_KEY = 'cc_anon_ai_chat_session_state';

type ResolveReject = { resolve: () => void; reject: () => void };
type PromiseWithPublicResolveReject = Promise<void> & ResolveReject;
const promiseWithPublicResolveReject = () => {
  const r = {} as ResolveReject;
  const p = new Promise<void>((resolve, reject) => {
    r.resolve = resolve;
    r.reject = reject;
  }) as PromiseWithPublicResolveReject;
  p.resolve = () => r.resolve();
  p.reject = () => r.reject();
  return p;
};

type AlertProps = {
  msg: string;
  type: 'error' | 'notice' | 'general';
};

const getExpId = () =>
  document.cookie
    .split('; ')
    .find((c) => c.startsWith('_cc_exp_id'))
    ?.split('=')[1] as string;

export const ChatBox: React.FC<ChatBoxProps> = ({ onClose, pageName }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [conversationId, setConversationId] = useState<string>('');
  const [chatToken, setChatToken] = useState<string>('');
  const chatTokenLoaded = useMemo(() => promiseWithPublicResolveReject(), []);
  const [captcha, setCaptcha] = useState<JSX.Element | null>(null);
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const [showDelete, setShowDelete] = useState(false);
  const clearChatBtnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const storredStateJson = sessionStorage.getItem(CHAT_SESSION_STATE_KEY);
    const s = storredStateJson?.length
      ? (JSON.parse(storredStateJson) as ChatSessionState)
      : null;
    if (s?.expId === getExpId()) {
      setMessages(s.messages);
      setConversationId(s.conversationId);
      setChatToken(s.chatToken);
      chatTokenLoaded.resolve();
    } else {
      setMessages([]);
      setConversationId(b36uuid());
      const [_captcha, promise] = sendCaptchaGuardedRequest({
        action: 'create_chat_token',
        requestV2: async (recaptchaToken) => getChatToken(recaptchaToken, 2),
        requestV3: async (recaptchaToken) => getChatToken(recaptchaToken, 3),
        reCaptchaKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
      });
      setCaptcha(_captcha);
      promise.then((r) => {
        if (r.status === 'complete') {
          setChatToken(r.result);
          chatTokenLoaded.resolve();
        } else {
          chatTokenLoaded.reject(); // caught by postMessage try/catch
        }
      });
    }
  }, [
    setMessages,
    setConversationId,
    setChatToken,
    setCaptcha,
    setAlert,
    chatTokenLoaded,
  ]);

  useEffect(() => {
    if (chatToken) {
      const s = {
        messages,
        conversationId,
        chatToken,
        expId: getExpId(),
      } as ChatSessionState;
      sessionStorage.setItem(CHAT_SESSION_STATE_KEY, JSON.stringify(s));
    }
  }, [messages, conversationId, chatToken]);

  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const scrollBoxRef = useRef<HTMLDivElement>(null);

  const postMessage = async (message: string) => {
    const userMessage = {
      id: b36uuid(),
      role: 'user',
      content: message,
      timestamp: isoTimestamp(),
    } as Message;
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);
    setAlert(null);
    setAwaitingResponse(true);
    await chatTokenLoaded;
    const result = await anonChat({
      body: JSON.stringify({
        messages: updatedMessages,
        chatToken,
        conversationId,
      }),
    });
    if (result !== null) {
      const updatedResponse = [...updatedMessages, result as Message];
      setMessages(updatedResponse);
    } else {
      setAlert({
        msg: 'Sorry, there was an error processing your request.',
        type: 'error',
      });
    }
    setAwaitingResponse(false);
  };

  const deleteChat = () => {
    setMessages([]);
    setConversationId(b36uuid());
    setAlert({
      msg: 'Messages cleared',
      type: 'general',
    });
  };

  useEffect(() => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollTop = scrollBoxRef.current.scrollHeight;
    }
    const hourAgo = new Date(+new Date() - 1000 * 60 * 60).toISOString();
    const lastHourMessageCount = messages.filter(
      (m: Message) => m.role === 'assistant' && m.timestamp >= hourAgo
    ).length;
    if (lastHourMessageCount >= 50) {
      setAlert({
        msg: 'Please check back in 1-2 hours to chat more.',
        type: 'notice',
      });
    } else if (lastHourMessageCount >= 45) {
      setAlert({
        msg: 'Just a quick note, we may soon reach our chat limit. Apologies for the inconvenience if this conversation is cut short.',
        type: 'notice',
      });
    }
  }, [messages]);

  useEffect(() => {
    if (conversationId) {
      trackUserClick({
        context: 'anon_ai_assistant',
        target: 'anon_ai_assistant_opened',
        misc: JSON.stringify({ conversationId }),
        page_name: pageName,
      });
    }
  }, [conversationId, pageName]);

  const lastMessage = messages[messages.length - 1];
  const messageToRead = lastMessage?.role === 'assistant' ? lastMessage : null;
  const messagesNotToRead = messageToRead ? messages.slice(0, -1) : messages;

  return (
    <FlexBox
      flexDirection="column"
      bottom={64}
      tabIndex={0}
      boxShadow="6px 6px var(--color-navy)"
      background={theme.colors.paleBlue}
      borderRadius="5px"
      border={1}
      width={{ _: 'calc(100vw - 24px)', xs: 416, sm: 502 }}
      maxHeight="100%"
      height={672}
      onKeyDown={(e) => {
        if (e.key === 'Escape' && !showDelete) {
          trackUserClick({
            context: 'anon_ai_assistant',
            target: 'anon_ai_assistant_closed',
            misc: JSON.stringify({ conversationId }),
            page_name: pageName,
          });
          onClose();
        }
      }}
      aria-label="AI Learning Assistant"
    >
      <FlexBox
        justifyContent="space-between"
        background={theme.colors['blue-500']}
        p={{ _: 12, xs: 16 }}
        borderRadiusTop="4px"
      >
        <FlexBox>
          <SparkleIcon size={24} color="white" mt={4} />
          <Text color="white" fontSize={18} pt={4} pl={8} as="h2">
            AI Learning Assistant
          </Text>
        </FlexBox>

        <FlexBox>
          <IconButton
            ref={clearChatBtnRef}
            mode="dark"
            aria-label="clear-chat"
            variant="secondary"
            icon={TrashIcon}
            onClick={() => setShowDelete(true)}
            size="small"
            tip="Clear messages"
            mr={4}
          />
          <IconButton
            mode="dark"
            aria-label="minimize-icon"
            variant="secondary"
            icon={ArrowChevronDownIcon}
            onClick={() => {
              trackUserClick({
                context: 'anon_ai_assistant',
                target: 'anon_ai_assistant_closed',
                misc: JSON.stringify({ conversationId }),
                page_name: pageName,
              });
              onClose();
            }}
            tip="Minimize chat"
            size="small"
          />
        </FlexBox>
      </FlexBox>
      <ScrollBox
        ref={scrollBoxRef}
        flexDirection="column"
        flexGrow={1}
        overflowX="hidden"
        overflowY="auto"
        p={{ _: 12, xs: 16 }}
        gap={8}
      >
        {messages.length === 0 && <Greeting />}
        {messagesNotToRead.map((m) => (
          <Box key={m.id}>
            <MessageBubble message={m} />
          </Box>
        ))}
        <Box aria-live="polite">
          {messageToRead && <MessageBubble message={messageToRead} />}
          {awaitingResponse && (
            <FlexBox justifyContent="left">
              <Box
                bg="navy-100"
                p={16}
                pb={24}
                borderRadius="8px"
                borderRadiusBottomLeft={0}
                mr={32}
              >
                <AnimatedLoadingDots />
              </Box>
            </FlexBox>
          )}
          {alert && (
            <Box aria-label={alert.msg}>
              <Alert
                placement="inline"
                type={alert.type}
                onClose={() => setAlert(null)}
              >
                {alert.msg}
              </Alert>
            </Box>
          )}
        </Box>
      </ScrollBox>
      <ChatInput
        postMessage={postMessage}
        awaitingResponse={awaitingResponse}
      />
      <FlexBox
        justifyContent="space-between"
        px={16}
        py={8}
        alignItems="center"
      >
        <NextLink
          href="https://codecademyready.typeform.com/to/PRJbh7Kj"
          passHref
        >
          <Anchor variant="interface" target="_blank">
            <Text variant="p-small" textDecoration="underline">
              Help us improve
            </Text>
          </Anchor>
        </NextLink>
        <FlexBox
          alignItems="center"
          onKeyDown={(evt) => {
            if (evt.key === 'Escape') {
              const infoTipIsOpen =
                (evt.target as HTMLElement).getAttribute('aria-expanded') ===
                'true';
              if (infoTipIsOpen) {
                evt.stopPropagation();
              }
            }
          }}
        >
          <StyledText variant="p-small">Powered by OpenAI</StyledText>{' '}
          <InfoTip
            alignment="top-left"
            info="The large language model may occasionally generate incorrect information."
          />
        </FlexBox>
      </FlexBox>
      {showDelete && (
        <Dialog
          isOpen
          onRequestClose={() => {
            setShowDelete(false);
            setTimeout(() => {
              clearChatBtnRef?.current?.focus();
            }, 5);
          }}
          title="Clear chat?"
          confirmCta={{ children: 'Clear chat', onClick: deleteChat }}
          cancelCta={{ children: 'Cancel', onClick: () => null }}
        >
          <Text>
            This will permanently remove your conversation history in this
            thread.
          </Text>
        </Dialog>
      )}
      <Box position="absolute">{captcha}</Box>
    </FlexBox>
  );
};
