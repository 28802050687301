import { ToolTip } from '@codecademy/gamut';
import React, { ReactNode } from 'react';

export const ChatToolTip: React.FC<{
  showToolTip: boolean;
  children: ReactNode;
}> = ({ showToolTip, children }) => {
  return showToolTip ? (
    <ToolTip info="AI Learning Assistant" id="chat-with-ai">
      {children}
    </ToolTip>
  ) : (
    children
  );
};
