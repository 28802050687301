import {
  BasicExperimentVariant,
  Experiment,
  useOptimizelyExperiment,
} from '@mono/data/optimizely';
import { useEffect, useState } from 'react';

export const useShowAI = (): boolean => {
  const [show, setShow] = useState(false);
  const anonAIExp =
    useOptimizelyExperiment(Experiment.ANON_AI_ASSISTANT_RETRY) ===
    BasicExperimentVariant.VARIANT;

  useEffect(() => {
    const isNonProd = window.location.hostname !== 'www.codecademy.com';
    if (isNonProd || anonAIExp) {
      setShow(true);
    }
  }, [anonAIExp]);

  return show;
};
