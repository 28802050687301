import { Box, Coachmark, FlexBox, Text, TextButton } from '@codecademy/gamut';
import { CheckerDense } from '@codecademy/gamut-patterns';
import React from 'react';

export const AnonAIAssistantCoachmark: React.FC<{
  showCoachmark: boolean;
  handleCloseCoachmark: () => void;
}> = ({ showCoachmark, handleCloseCoachmark }) => {
  const renderCoachmarkContent = () => (
    <FlexBox width={296} column>
      <Text variant="title-sm" id="coachmark-title">
        Got a question?
      </Text>
      <Text variant="p-base" py={8}>
        Ask our AI Learning Assistant for guidance on what to learn based on
        your goals.
      </Text>
      <FlexBox justifyContent="flex-end">
        <TextButton onClick={handleCloseCoachmark} variant="primary">
          <Text fontSize={16}>OK</Text>
        </TextButton>
      </FlexBox>
    </FlexBox>
  );

  return (
    <Box data-testid="anon-ai-assistant-coachmark">
      <Coachmark
        popoverProps={{
          align: 'left',
          beak: 'right',
          horizontalOffset: 256,
          outline: true,
          pattern: CheckerDense,
          position: 'above',
          role: 'region',
        }}
        renderPopover={renderCoachmarkContent}
        shouldShow={showCoachmark}
        aria-labelledby="coachmark-title"
      />
    </Box>
  );
};
