import { Box } from '@codecademy/gamut';
import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { GuardedCaptchaProvider } from '@mono/ui/captcha';
import { useEffect, useState } from 'react';

import { trackUserClick } from '~/libs/tracking';

import { ChatBox } from './ChatBox';
import { ChatBtn } from './ChatBtn';
import { AnonAIAssistantCoachmark } from './Coachmark';
import { useShowAI } from './useShowAI';

const OPEN_COSE_TRANSITION_MS = 200;
const DURATION_EASING = `${OPEN_COSE_TRANSITION_MS}ms cubic-bezier(0,0,.2,1.1)`;

const ScaleBox = styled(Box)(
  ({ origin, scale }: { origin: string; scale: number }) =>
    css({
      transformOrigin: origin,
      transform: `scale(${scale})`,
      transition: `transform ${DURATION_EASING}, opacity ${DURATION_EASING}`,
      display: 'flex',
      flexDirection: 'column-reverse',
      pointerEvents: 'none',
      '> *': {
        pointerEvents: 'auto',
      },
    })
);

const SHOW_ANON_ASSISTANT_COACHMARK_STATE_KEY = 'show_anon_assistant_coachmark';

export const AnonAIAssistant: React.FC<{ pageName: string }> = ({
  pageName,
}) => {
  const showAI = useShowAI(); // checks experiment, query param, screen width
  const [open, setOpen] = useState(false);
  const [delayedOpen, setDelayedOpen] = useState(false);
  const [showCoachmark, setShowCoachmark] = useState(true);

  useEffect(() => {
    const t = setTimeout(() => {
      setDelayedOpen(open);
    }, OPEN_COSE_TRANSITION_MS);
    return () => clearTimeout(t);
  }, [open]);

  // both are visible during transition
  const showBox = open || delayedOpen;
  const showBtn = !open || !delayedOpen;

  const showToolTip = !showCoachmark;

  useEffect(() => {
    const shouldShowAIAssistantCoachmarkSession = sessionStorage.getItem(
      SHOW_ANON_ASSISTANT_COACHMARK_STATE_KEY
    );
    if (shouldShowAIAssistantCoachmarkSession === 'false') {
      setShowCoachmark(false);
    }
  }, [setShowCoachmark]);

  const handleCloseCoachmark = () => {
    trackUserClick({
      context: 'anon_ai_assistant',
      target: 'anon_ai_assistant_coachmark_close',
      page_name: pageName,
    });
    setShowCoachmark(false);
    sessionStorage.setItem(SHOW_ANON_ASSISTANT_COACHMARK_STATE_KEY, 'false');
  };

  return showAI ? (
    <GuardedCaptchaProvider>
      <div role="region" aria-label="AI Learning Assistant">
        <ScaleBox
          position="fixed"
          bottom={{ _: 16, xs: 20, sm: 24, md: 28, lg: 32 }}
          right={{ _: 16, xs: 32, sm: 40, md: 48, lg: 56 }}
          top={160}
          origin="bottom right"
          scale={open ? 1 : 0}
          opacity={open ? 1 : 0}
          zIndex={11}
        >
          {showBox && (
            <ChatBox onClose={() => setOpen(false)} pageName={pageName} />
          )}
        </ScaleBox>
        <ScaleBox
          position="fixed"
          bottom={{ _: 16, xs: 20, sm: 24, md: 28, lg: 32 }}
          right={{ _: 24, xs: 32, sm: 40, md: 48, lg: 56 }}
          origin="center"
          scale={open ? 0 : 1}
          opacity={open ? 0 : 1}
          zIndex={10}
        >
          {showBtn && (
            <>
              <ChatBtn
                ref={(el) => delayedOpen && el?.focus()}
                onClick={() => setOpen(true)}
                showToolTip={showToolTip}
              />
              <AnonAIAssistantCoachmark
                showCoachmark={showCoachmark}
                handleCloseCoachmark={handleCloseCoachmark}
              />
            </>
          )}
        </ScaleBox>
      </div>
    </GuardedCaptchaProvider>
  ) : null;
};
