import { Anchor, Box, FlexBox, Text } from '@codecademy/gamut';
import Image from 'next/image';
import { useWindowSize } from 'react-use';

import GreetingIcon from '../assets/assistant_greeting.svg';

export const Greeting = () => {
  const { width } = useWindowSize();
  return (
    <FlexBox
      height="100%"
      justifyContent="left"
      flexDirection="column"
      px={{ _: 16, xs: 24, sm: 32 }}
      pt={{ _: 12, xs: 16 }}
    >
      {width >= 768 && (
        <Image
          src={GreetingIcon}
          alt="Chat bubbles"
          width={141}
          height={123}
          aria-hidden
        />
      )}
      <Box pt={{ _: 16, xs: 24 }}>
        <Text variant="title-md" as="h3">
          What would you like to learn?
        </Text>
        <Text variant="p-large" pt={{ _: 16, xs: 24 }}>
          Share a little about your interests, and the AI Learning Assistant can
          help you find courses that fit your goals. All you need to do is ask!
        </Text>
        <Text variant="p-small" pt={{ _: 16, xs: 64 }}>
          {`By using this chat, you agree to the Codecademy `}
          <Anchor href="https://www.codecademy.com/policy">
            Privacy Policy
          </Anchor>
          {` and `}
          <Anchor href="https://www.codecademy.com/terms">Terms of Use</Anchor>.
          Do not enter any personally identifying or confidential information.
        </Text>
      </Box>
    </FlexBox>
  );
};
